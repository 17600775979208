import { object, string } from 'yup';

import { isValidUrl } from '@@/form-components/validate-url';

export function noDuplicateNamesSchema(names: string[]) {
  return string()
    .required('Name is required')
    .test('not existing name', 'Name is already in use', (newName) =>
      names.every((name) => name !== newName)
    );
}

export function validationSchema(names: string[], isEditing = false) {
  return object().shape({
    name: noDuplicateNamesSchema(names),
    credentials: object()
      .shape({
        endpoint: string()
          .test(
            'valid API server URL',
            'The endpoint must be a valid URL',
            (value) => isValidUrl(value)
          )
          .required('Endpoint is required'),
        serviceAccountKey: serviceAccountKeyValidation(isEditing),
      })
      .required(),
  });
}

function serviceAccountKeyValidation(isEditing: boolean) {
  if (isEditing) {
    return string();
  }
  return string().required('Service account token is required');
}
