import { Form, Formik, useFormikContext } from 'formik';
import { Plus } from 'lucide-react';

import { Credential } from '@/react/portainer/settings/sharedCredentials/types';
import { notifySuccess } from '@/portainer/services/notifications';
import { useCustomTemplates } from '@/react/portainer/templates/custom-templates/queries/useCustomTemplates';
import { StackType } from '@/react/common/stacks/types';
import { useTalosVersionOptions } from '@/react/kubernetes/cluster/omni/queries/useTalosVersions';
import { useInstallOmniCluster } from '@/react/kubernetes/cluster/omni/queries/useInstallOmniCluster';
import { Environment } from '@/react/portainer/environments/types';
import {
  buildDefaultValue as buildTunnelDefaultValue,
  PortainerTunnelAddrField,
} from '@/react/portainer/common/PortainerTunnelAddrField';
import {
  buildDefaultValue as buildApiUrlDefaultValue,
  PortainerUrlField,
} from '@/react/portainer/common/PortainerUrlField';
import {
  OmniClusterMachineSet,
  OmniMachineFormValues,
  OmniMachinePayload,
} from '@/react/kubernetes/cluster/omni/types';
import { BetaAlert } from '@/react/portainer/environments/update-schedules/common/BetaAlert';
import { useSettings } from '@/react/portainer/settings/queries';
import { isOmniCredentials } from '@/react/portainer/settings/sharedCredentials/utils';

import { PortainerSelect } from '@@/form-components/PortainerSelect';
import { TextTip } from '@@/Tip/TextTip';
import { FormControl } from '@@/form-components/FormControl';
import { FormActions } from '@@/form-components/FormActions';
import { FormSection } from '@@/form-components/FormSection';
import { WebEditorForm } from '@@/WebEditorForm';

import { NameField } from '../../shared/NameField';
import { CredentialsField } from '../../WizardKaaS/shared/CredentialsField';
import { useSetAvailableOption } from '../../WizardKaaS/useSetAvailableOption';
import { CustomTemplateSelector } from '../../shared/CustomTemplateSelector';
import { MetadataFieldset } from '../../shared/MetadataFieldset';
import { AnalyticsStateKey } from '../../types';

import { CreateOmniClusterPayload, OmniCreateClusterFormValues } from './types';
import { useOmniValidation } from './useOmniValidation';
import { getMajorAndMinorString } from './utils';
import { MachineSelectInput } from './MachineSelectInput';

const exampleClusterConfig = `# cluster:
#   network:
#     cni:
#       flannel:
#         extraArgs:
#           - --iface-can-reach=\${GATEWAY_IP}
#       name: flannel
# machine:
#   network:
#     kubespan:
#       enabled: true
`;

const initialMachineSets: OmniClusterMachineSet[] = [
  {
    name: 'Control Plane',
    role: 'control-plane',
    machines: [],
  },
  {
    name: 'Main worker pool',
    role: 'worker',
    machines: [],
  },
];

interface Props {
  onCreate(environment: Environment, analytics: AnalyticsStateKey): void;
  credentials: Credential[];
}

export function OmniCreateClusterForm({ onCreate, credentials }: Props) {
  const installOmniClusterQuery = useInstallOmniCluster();
  const initialValues = useInitialValues(credentials);
  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validationSchema={useOmniValidation()}
      validateOnMount
      enableReinitialize
    >
      <OmniCreateClusterInnerForm credentials={credentials} />
    </Formik>
  );

  async function handleSubmit(
    values: OmniCreateClusterFormValues,
    {
      setFieldValue,
    }: {
      setFieldValue: (
        field: string,
        value: string | OmniClusterMachineSet[],
        shouldValidate?: boolean
      ) => void;
    }
  ) {
    const payload: CreateOmniClusterPayload = getPayloadFromFormValues(values);

    // use mutateAsync so that isSubmitting is true while the request is in progress
    await installOmniClusterQuery.mutateAsync(
      { payload, credentialId: values.credentialId },
      {
        onSuccess: (environment) => {
          notifySuccess('Success', 'Cluster provisioning started');
          onCreate(environment, 'kubernetesInstallOmni');
          setFieldValue('name', '');
          setFieldValue('omni.machineSets', initialMachineSets);
        },
      }
    );
  }
}

function getPayloadFromFormValues(
  values: OmniCreateClusterFormValues
): CreateOmniClusterPayload {
  return {
    portainerUrl: values.omni.portainerUrl,
    tunnelServerAddr: values.omni.tunnelServerAddr,
    cluster: {
      kind: 'Cluster',
      name: values.name,
      kubernetes: {
        version: values.omni.kubernetesVersion,
      },
      talos: {
        version: values.omni.talosVersion,
      },
    },
    controlPlane: {
      kind: 'ControlPlane',
      machines: values.omni.machineSets
        .filter((set) => set.role === 'control-plane')
        .flatMap((set) => set.machines),
    },
    worker: {
      kind: 'Workers',
      machines: values.omni.machineSets
        .filter((set) => set.role === 'worker')
        .flatMap((set) =>
          set.machines.map(transformMachineValuesToMachinePayload)
        ),
    },
    clusterConfig:
      values.omni.clusterConfig === exampleClusterConfig ||
      !values.omni.clusterConfig
        ? ''
        : values.omni.clusterConfig,
  };
}

export function transformMachineValuesToMachinePayload(
  machineValues: OmniMachineFormValues
): OmniMachinePayload {
  if (!machineValues.applyConfig) {
    return {
      name: machineValues.name,
    };
  }

  return {
    name: machineValues.name,
    hostname: machineValues.hostname,
    interfaces: machineValues.interfaces,
    nameservers: machineValues.nameservers,
  };
}

function useInitialValues(
  credentials: Credential[]
): OmniCreateClusterFormValues {
  const talosVersionOptionsQuery = useTalosVersionOptions({
    credentialId: credentials[0].id,
  });
  const talosOptions = talosVersionOptionsQuery.data?.talosVersionOptions ?? [];
  const compatibleK8sOptions =
    talosVersionOptionsQuery.data?.compatibleK8sVersionOptions ?? {};

  const initialTalosVersion = talosOptions[0]?.value ?? '';
  const initialK8sVersion =
    compatibleK8sOptions[initialTalosVersion ?? '']?.[0]?.value ?? '';

  const settingsQuery = useSettings();
  const portainerUrl =
    settingsQuery.data?.EdgePortainerUrl || buildApiUrlDefaultValue();
  const tunnelServerAddr =
    settingsQuery.data?.Edge.TunnelServerAddress || buildTunnelDefaultValue();

  return {
    name: '',
    credentialId: 0,
    meta: {
      groupId: 1,
      tagIds: [],
    },
    omni: {
      machineSets: initialMachineSets,
      kubernetesVersion: initialK8sVersion,
      talosVersion: initialTalosVersion,
      clusterConfig: exampleClusterConfig,
      portainerUrl,
      tunnelServerAddr,
    },
  };
}

interface InnerFormProps {
  credentials: Credential[];
}

function OmniCreateClusterInnerForm({ credentials }: InnerFormProps) {
  const { values, setFieldValue, isSubmitting, isValid, errors } =
    useFormikContext<OmniCreateClusterFormValues>();

  const talosVersionOptionsQuery = useTalosVersionOptions({
    credentialId: values.credentialId,
  });
  const talosOptions = talosVersionOptionsQuery.data?.talosVersionOptions ?? [];
  const compatibleK8sOptions =
    talosVersionOptionsQuery.data?.compatibleK8sVersionOptions ?? {};
  const customTemplatesQuery = useCustomTemplates({
    select: (templates) =>
      templates.filter((t) => t.Type === StackType.Kubernetes),
  });
  const customTemplates = customTemplatesQuery.data ?? [];
  useSetAvailableOption(
    compatibleK8sOptions[values.omni.talosVersion],
    values.omni.kubernetesVersion,
    'omni.kubernetesVersion'
  );

  const omniCredentials = credentials.find(
    (credential) => credential.id === values.credentialId
  )?.credentials;
  const omniEndpoint = isOmniCredentials(omniCredentials)
    ? omniCredentials?.endpoint
    : '';

  return (
    <Form className="form-horizontal">
      <BetaAlert
        isHtml
        message={
          <p>
            Beta feature - so far, Omni cluster management has only been tested
            in a limited set of scenarios.
          </p>
        }
      />
      <TextTip color="blue" className="mb-2" inline={false}>
        This will allow you to create a Kubernetes cluster using Omni with your
        own existing Talos nodes, and will then deploy the Portainer agent to
        it.
      </TextTip>

      <NameField
        tooltip="Name of the cluster and environment."
        placeholder="e.g. my-cluster-name"
      />

      <FormSection title="Portainer server details">
        <PortainerUrlField fieldName="omni.portainerUrl" required />
        <PortainerTunnelAddrField fieldName="omni.tunnelServerAddr" required />
      </FormSection>

      <FormSection title="Omni cluster summary">
        <CredentialsField credentials={credentials} />

        <FormControl
          label="Talos version"
          tooltip="Talos version running on the cluster."
          inputId="omni-talosVersion"
          isLoading={talosVersionOptionsQuery.isLoading}
          errors={
            typeof errors.omni === 'string'
              ? errors.omni // message from mismatching talos version validation
              : errors.omni?.talosVersion
          }
        >
          <PortainerSelect
            value={values.omni.talosVersion}
            onChange={(value: string) => {
              setFieldValue('omni.talosVersion', value);
            }}
            data-cy="omniCreateForm-talosVersionSelect"
            options={talosOptions}
          />
        </FormControl>

        <FormControl
          label="Kubernetes version"
          tooltip={
            <>
              Kubernetes version running on the cluster.
              <br />
              <a
                target="_blank"
                href={`https://www.talos.dev/${getMajorAndMinorString(
                  values.omni.talosVersion
                )}/introduction/support-matrix/`}
                rel="noreferrer"
              >
                Learn more about Kubernetes version compatibility.
              </a>
            </>
          }
          inputId="microk8s-kubernetesVersion"
          isLoading={talosVersionOptionsQuery.isLoading}
        >
          <PortainerSelect
            value={values.omni.kubernetesVersion}
            onChange={(value: string) =>
              setFieldValue('omni.kubernetesVersion', value)
            }
            data-cy="omniCreateForm-kubernetesVersionSelect"
            options={compatibleK8sOptions[values.omni.talosVersion] ?? []}
          />
        </FormControl>
      </FormSection>

      <FormSection title="Cluster machines">
        {!!omniEndpoint && (
          <div className="flex w-full">
            <TextTip color="blue" className="mb-2" inline={false}>
              To add Talos nodes,{' '}
              <a
                href={`${omniEndpoint}/omni/?modal=downloadInstallationMedia`}
                target="_blank"
                rel="noreferrer"
                data-cy="omniCreateForm-downloadInstallationMediaLink"
              >
                download the Omni ISO image
              </a>{' '}
              and boot your machines using this image. For more information, see
              the{' '}
              <a
                href="https://omni.siderolabs.com/tutorials/getting_started#download-installation-media"
                target="_blank"
                rel="noreferrer"
              >
                Omni documentation
              </a>
              .
            </TextTip>
          </div>
        )}
        {values.omni.machineSets.map((machineSet, index) => (
          <MachineSelectInput
            key={machineSet.name}
            machineSet={machineSet}
            index={index}
          />
        ))}
      </FormSection>

      <FormSection title="Cluster Configuration patch" isFoldable>
        <WebEditorForm
          titleContent="Edit Cluster Configuration patch"
          id="omni-cluster-config"
          onChange={(value: string) => {
            setFieldValue('omni.clusterConfig', value);
          }}
          type="yaml"
          value={values.omni.clusterConfig ?? ''}
          data-cy="omniCreateForm-clusterConfig"
        >
          <TextTip color="blue">
            Cluster Configuration Patches allow you to customize settings for
            all nodes in a cluster or specific groups (such as control plane or
            worker nodes). See the{' '}
            <a
              target="_blank"
              href={`https://www.talos.dev/${getMajorAndMinorString(
                values.omni.talosVersion
              )}/reference/configuration/`}
              rel="noreferrer"
            >
              Talos Config Reference
            </a>{' '}
            for more information.
          </TextTip>
        </WebEditorForm>
      </FormSection>

      <FormSection title="More settings" className="ml-0" isFoldable>
        <div className="ml-8">
          <CustomTemplateSelector customTemplates={customTemplates} />
          <MetadataFieldset>
            <TextTip color="blue" className="mb-2">
              Metadata is only assigned to the environment in Portainer, i.e.
              the group and tags are not assigned to the cluster within the Omni
              dashboard.
            </TextTip>
          </MetadataFieldset>
        </div>
      </FormSection>
      <FormActions
        submitLabel="Provision environment"
        loadingText="Provisioning..."
        submitIcon={Plus}
        isLoading={isSubmitting}
        data-cy="provision-omni-environment-button"
        isValid={isValid}
      />
    </Form>
  );
}
